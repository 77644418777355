@import url('https://fonts.cdnfonts.com/css/kablam');
@font-face {
  font-family: "BadaBoom Pro BB";
  src: url("https://db.onlinewebfonts.com/t/b48816312e9e52094ea85f8d14df9e1e.eot");
  src: url("https://db.onlinewebfonts.com/t/b48816312e9e52094ea85f8d14df9e1e.eot?#iefix")format("embedded-opentype"),
  url("https://db.onlinewebfonts.com/t/b48816312e9e52094ea85f8d14df9e1e.woff2")format("woff2"),
  url("https://db.onlinewebfonts.com/t/b48816312e9e52094ea85f8d14df9e1e.woff")format("woff"),
  url("https://db.onlinewebfonts.com/t/b48816312e9e52094ea85f8d14df9e1e.ttf")format("truetype"),
  url("https://db.onlinewebfonts.com/t/b48816312e9e52094ea85f8d14df9e1e.svg#BadaBoom Pro BB")format("svg");
}

html,
body {
  margin: 0;
  padding: 0;
}

*{
  /* font-family: "Comic Sans", cursive; */
  font-family: 'BadaBoom Pro BB', sans-serif;
}

.comic {
  display: flex;
  flex-wrap: wrap;
  font-family: "Comic Sans", cursive;
  padding: 1vmin;
}

.panel {
  box-shadow: 0 6px 6px -6px #000;
  flex: 1 1;
  margin: 1vmin;
}

.text {
  background-color: #fff;
  border: solid 2px #000;
  margin: 0;
  padding: 3px 10px;
}

.top-left {
  left: -6px;
  position: absolute;
  top: -2px;
  transform: skew(-15deg);
}

.bottom-right {
  bottom: -2px;
  position: absolute;
  right: -6px;
  transform: skew(-15deg);
}

.speech {
  background-color: #fff;
  border: solid 2px #000;
  border-radius: 12px;
  display: inline-block;
  margin: 0.5em;
  padding: 0.5em 1em;
  position: relative;
}

.speech:before {
  border: solid 12px transparent;
  border-left: solid 12px #000;
  border-top: solid 12px #000;
  bottom: -24px;
  content: "";
  height: 0;
  left: 24px;
  position: absolute;
  transform: skew(-15deg);
  width: 0;
}

.speech:after {
  border: solid 10px transparent;
  border-left: solid 10px #fff;
  border-top: solid 10px #fff;
  bottom: -19px;
  content: "";
  height: 0;
  left: 27px;
  position: absolute;
  transform: skew(-15deg);
  width: 0;
}

.panel:nth-child(1) {
  flex-basis: 400px;
}
.panel:nth-child(2) {
  flex-basis: 300px;
}
.panel:nth-child(3) {
  flex-basis: 275px;
}
.panel:nth-child(4) {
  flex-basis: 300px;
}
.panel:nth-child(5) {
  flex-basis: 450px;
}
.panel:nth-child(6) {
  flex-basis: 325px;
}
.panel:nth-child(7) {
  flex-basis: 375px;
}
.panel:nth-child(8) {
  flex-basis: 425px;
}
.panel:nth-child(9) {
  flex-basis: 380px;
}
.panel:nth-child(10) {
  flex-basis: 300px;
}
